/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* global chrome */
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import RoundedButton from "src/Components/Buttons/RoundedButton";
import ChevronLeft from "src/Components/Icons/ChevronLeft";
import Trash from "src/Components/Icons/Trash";
import { DeleteAsset } from "src/Components/Modals/DeleteAsset";
import ErrorModal from "src/Components/Modals/Error";
import FundAssets from "src/Components/Modals/Children/FundAssets";
import Modal from "src/Components/Modals/Modal";
import CopiedToClipboard from "src/Components/Modals/CopiedToClipboard";
import { Location } from "src/types";
import { AssetView } from "src/types/views";
import { addBalance } from "src/Hooks/util";
import CopyButton from "src/Components/Buttons/CopyButton";
import DefaultMenu from "src/Components/Menus/Menu";
import Dots from "src/Components/Icons/Dots";
import { isExtension } from "src/lib";
import RgbAsset from "src/Components/Icons/RgbAsset";

const AssetDetails = () => {
  const navigate = useNavigate();
  const location = useLocation() as Location;
  const {
    walletData,
    lnCredentials,
    vault,
    asset,
    assetUtxos,
    funded,
    locationHistory,
    tokensWalletAddress,
    udasWalletAddress,
    pendingAssetTransfers,
    hash,
  } = location.state;

  let { network } = location?.state ?? {};

  if (!network) {
    const networkStore = localStorage.getItem("network");
    if (!networkStore) {
      console.debug("network store undefinied");
      network = "bitcoin";
    } else {
      network = networkStore;
    }
  }

  const [openFundAssetsModal, setOpenFundAssetsModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, _setError] = useState({
    title: "",
    message: "",
  });
  const [openCopyModal, setOpenCopyModal] = useState(false);
  const [assetView, _] = useState(new AssetView(asset));

  return (
    <div className="flex justify-center w-11/12 h-auto mx-auto mb-6 overflow-x-hidden overflow-y-auto darkscrollbar min-h-page 4xl:w-4/12 3xl:w-5/12 2xl:w-6/12 xl:w-7/12 lg:w-8/12 lg:mb-12">
      <div className="flex flex-col w-full mx-auto">
        <div className="flex flex-row w-full h-auto mx-auto">
          <button
            type="button"
            className="flex flex-row mt-3 mb-3 text-black cursor-pointer lg:mt-6 flex-nowrap dark:text-white dark:hover:text-gray-300 hover:text-gray-700"
            onClick={() =>
              navigate("/wallet", {
                state: { wallet: walletData.name, vault, hash, lnCredentials },
              })
            }
          >
            <ChevronLeft className="w-8 h-8 m-auto font-bold" />
            <h1 className="m-auto ml-3 text-2xl font-medium text-left flex-grow-default">
              Asset Details
            </h1>
          </button>
          <button
            type="button"
            onClick={() => {
              setOpen(true);
            }}
            className="hidden my-auto ml-auto mr-3 disabled:opacity-50 disabled:pointer-events-none"
            disabled
          >
            <Trash className="w-8 h-8 text-gray-800 dark:text-gray-300 dark:hover:text-gray-500 hover:text-gray-600 margin-auto" />
          </button>
        </div>
        <div className="flex flex-col justify-center w-11/12 h-auto m-auto my-3 sm:my-6 xs:w-9/12 max-h-10/12">
          <div className="w-full p-6 pt-0 m-auto mt-6 text-center border-1 dark:border-darkmode-700 rounded-xl">
            <DefaultMenu
              originBottom
              title="Asset Options"
              items={[
                {
                  name: "Publish Contract",
                  onClick: async () => {
                    if (network === "bitcoin") {
                      await fetch(
                        `${
                          isExtension ? "https://beta.bitmask.app" : ""
                        }/api/createasset`,
                        {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                          },
                          body: JSON.stringify(
                            {
                              asset: {
                                creatorXpubkh: vault.public.xpubkh,
                                ticker: asset.ticker,
                                name: asset.name,
                                description: asset.description,
                                supply: asset.supply,
                                precision: asset.precision,
                                contractId: asset.contractId,
                                contractGenesis: asset.contract.armored,
                              },
                              auth: {
                                xpubkh: vault.public.xpubkh,
                                xprvkh: vault.private.xprvkh,
                              },
                            },
                            (_key, value) =>
                              typeof value === "bigint"
                                ? value.toString()
                                : value
                          ),
                        }
                      );
                    }
                  },
                },
              ]}
              className="absolute right-0 ml-auto mr-0 top-2 w-7"
            >
              <Dots className="w-6 h-6 my-auto text-gray-800 dark:text-white" />
            </DefaultMenu>
            <RgbAsset className="w-20 h-20 m-auto" />
            <p className="my-3 text-2xl font-semibold text-black dark:text-gray-300">
              {`${Number(assetView.balance()).toLocaleString()} ${
                asset.ticker
              }`}
            </p>
            <p className="text-base font-thin text-gray-800 dark:text-gray-500">
              {asset.name}
            </p>
          </div>
          <CopyButton
            className="w-full mx-auto"
            title="asset id"
            handleOnClick={() => {
              navigator.clipboard.writeText(asset.contractId);
              setOpenCopyModal(true);
            }}
          >
            {asset.contractId}
          </CopyButton>
          <CopyButton
            className="w-full mx-auto"
            title="asset contract"
            handleOnClick={() => {
              navigator.clipboard.writeText(asset.contract.armored);
              setOpenCopyModal(true);
            }}
          >
            {asset.contract.armored}
          </CopyButton>
          <div className="w-full pb-3 m-auto my-3 text-black sm:my-0 dark:text-gray-400 sm:pb-6">
            <div className="grid grid-cols-2 border-b-1 dark:border-darkmode-700">
              <p className="my-4 text-base font-thin text-left md:text-lg flex-grow-default dark:text-gray-500">
                Asset Ticker:
              </p>
              <p className="my-4 text-base font-normal text-right truncate md:text-lg flex-grow-default">
                {assetView.ticker()}
              </p>
            </div>
            <div className="grid grid-cols-2 border-b-1 dark:border-darkmode-700">
              <p className="my-4 text-base font-thin text-left md:text-lg flex-grow-default dark:text-gray-500">
                Asset Name:
              </p>
              <p className="my-4 text-base font-normal text-right truncate md:text-lg flex-grow-default">
                {assetView.name()}
              </p>
            </div>
            <div className="grid grid-cols-2 border-b-1 dark:border-darkmode-700">
              <p className="my-4 text-base font-thin text-left md:text-lg flex-grow-default dark:text-gray-500">
                Supply:
              </p>
              <p className="my-4 text-base font-normal text-right truncate md:text-lg flex-grow-default">
                {Number(assetView.supply()).toLocaleString()}
              </p>
            </div>
            <div className="grid grid-cols-2 border-b-1 dark:border-darkmode-700">
              <p className="my-4 text-base font-thin text-left md:text-lg flex-grow-default dark:text-gray-500">
                Precision:
              </p>
              <p className="my-4 text-base font-normal text-right truncate md:text-lg flex-grow-default">
                {assetView.precision()}
              </p>
            </div>
            <div className="grid grid-cols-2 border-b-1 dark:border-darkmode-700">
              <p className="my-4 text-base font-thin text-left md:text-lg flex-grow-default dark:text-gray-500">
                Holdings:
              </p>
              <p className="my-4 text-base font-normal text-right truncate md:text-lg flex-grow-default">
                {Number(assetView.balance()).toLocaleString()}
              </p>
            </div>
            <div className="grid grid-cols-2 border-b-1 dark:border-darkmode-700">
              <p className="my-4 text-base font-thin text-left md:text-lg flex-grow-default dark:text-gray-500">
                % of Supply:
              </p>
              <p className="my-4 text-base font-normal text-right truncate md:text-lg flex-grow-default">
                {assetView.holding()}
              </p>
            </div>
          </div>
        </div>
        {window.localStorage.getItem("network") !== "bitcoin" && (
          <div className="w-11/12 pb-6 mx-auto text-xl font-semibold xs:w-9/12 sm:pb-12 lg:pb-20">
            <div className="w-full m-auto xs:mt-6 grid grid-cols-2">
              <RoundedButton
                className="text-base text-black bg-yellow-500 disabled:bg-opacity-50"
                title={
                  pendingAssetTransfers
                    ? "Pending asset transfers. Transfers temporarily unavailable."
                    : "send asset"
                }
                disabled={pendingAssetTransfers}
                onClick={() => {
                  navigate("/send", {
                    state: {
                      ...location.state,
                      locationHistory: [
                        ...locationHistory,
                        window.location.hash,
                      ],
                      type: "Asset",
                    },
                  });
                }}
              >
                Send
              </RoundedButton>
              <RoundedButton
                className="ml-2 text-base text-black bg-yellow-500"
                onClick={() => {
                  navigate("/accepttransaction", {
                    state: {
                      ...location.state,
                      type: "Asset",
                      locationHistory: [
                        ...locationHistory,
                        window.location.hash,
                      ],
                    },
                  });
                }}
              >
                Accept
              </RoundedButton>
            </div>
            <RoundedButton
              className="w-full m-auto mt-4 text-base text-black bg-yellow-500"
              onClick={() => {
                if (funded) {
                  // We will need to determine how to decern which asset type we are dealing with
                  // and pass it to receive. Set as Asset for now.
                  navigate("/receive", {
                    state: {
                      ...location.state,
                      assetUtxos,
                      type: "Asset",
                      precision: asset.precision,
                      locationHistory: [
                        ...locationHistory,
                        window.location.hash,
                      ],
                      contractId: asset.contractId,
                    },
                  });
                } else {
                  // If we don't have the necessary utxas in assets wallet, we say the user tu fund it
                  setOpenFundAssetsModal(true);
                }
              }}
            >
              {funded ? "Generate Invoice" : "Fund assets wallet"}
            </RoundedButton>
          </div>
        )}
      </div>
      <Modal open={openFundAssetsModal} setOpen={setOpenFundAssetsModal}>
        <FundAssets
          walletBalance={addBalance(walletData?.balance)}
          vault={vault}
          udasWalletAddress={udasWalletAddress}
          tokensWalletAddress={tokensWalletAddress}
          setOpenFundAssetsModal={setOpenFundAssetsModal}
          network={network}
        />
      </Modal>
      <CopiedToClipboard open={openCopyModal} setOpen={setOpenCopyModal} />
      <DeleteAsset
        open={open}
        setOpen={setOpen}
        walletData={walletData}
        assetData={asset}
        lnCredentials={lnCredentials}
        hash={hash}
      />
      <ErrorModal
        open={openError}
        setOpen={setOpenError}
        message={error.message}
        title={error.title}
      />
    </div>
  );
};

export default AssetDetails;
